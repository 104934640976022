<template>
    <div style="width: 100vw; height: 100vh;">
        <iframe id="payReturnIframe" :srcdoc="ifremData" frameborder="0" v-if="payType === 'html'"></iframe>
        <iframe id="payReturnIframe" :src="ifremData" frameborder="0" v-else></iframe>
    </div>
</template>

<script>
    export default {
        components: {
           
        },
       
        data() {
            return {
                ifremData: null,
                payType: null,
                postState:{
                    getExtInfo:false,
                    getUserToken:false,
                    getUserId:false
                }
            }
        },
        created() {
          this.ifremData = this.$route.params.url 
          this.payType = this.$route.params.pullType
        },
        mounted() {
          this.getPayPageMessage()  
        },
        methods:{
            listenerFunction(e) {
                let that = this
                // console.log(e.data.gsFloatEventType)
                switch (e.data.gsFloatEventType) {
                    case "backGame":
                        // console.log('接收参数')
                        that.$common.postMessageToWindowParent('closeDragView', null)
                       break
                    case 'backFloat':
                        window.removeEventListener('message', this.listenerFunction)
                       that.$router.replace({
                           name: 'PlatformCoinIndex',
                           params: {}
                       })
                       break
                    case 'getExtInfo':
                        if(this.postState.getExtInfo) {
                            return
                        }
                       that.$common.postMessageToIframe('extInfoCallback', that.$store.state.extInfo)
                       this.postState.getExtInfo = true
                       break
                    case 'getUserToken':
                        if(this.postState.getUserToken) {
                            return
                        }
                       that.$common.postMessageToIframe('userTokenCallback', that.$store.state.token)
                       this.postState.getUserToken = true
                       break
                    case 'getUserId':
                        if(this.postState.getUserId) {
                            return
                        }
                       that.$common.postMessageToIframe('userIdCallback', that.$store.state.userId)
                       this.postState.getUserId = true
                       break
                }
            },
            //web环境下监听支付回调页的方法
            getPayPageMessage() {
                
                window.addEventListener('message', this.listenerFunction)
            }
        }
    }
</script>

<style scoped>
    iframe {
        height: 100%;
        width: 100%;
        
    }
</style>
